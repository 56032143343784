body {
  margin: 0;
  font-family: 'Karla', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiDataGrid-main .MuiDataGrid-cell[data-field='seller_sku'] {
  display: flex;
  align-items: center;
  line-height: 15px !important;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: break-spaces;
}

.MuiDataGrid-panelContent {
  max-width: 99vw;
}

.custom-table .toolbar-container {
  flex-wrap: wrap;
}

#BeaconInlineArticlesFrame {
  z-index: 1200 !important;
}

.Article__titleHeading #article-modal-header {
  color: #174582 !important;
}

#BeaconInlineArticlesFrame .gJlwEu .c-Modal__innerWrapper {
  height: auto !important;
  background-color: red !important;
}

.MuiDataGrid-root.custom-table .MuiDataGrid-main .MuiDataGrid-cell:not([data-field='seller_sku']) span,
.MuiDataGrid-root.custom-table .MuiDataGrid-main .MuiDataGrid-cell:not([data-field='seller_sku']) {
  display: flex;
  align-items: center;
  line-height: 15px !important;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: break-spaces;
}

.MuiDataGrid-root.custom-table.drive .MuiDataGrid-main .MuiDataGrid-cell span,
.MuiDataGrid-root.custom-table.drive .MuiDataGrid-main .MuiDataGrid-cell,
.MuiDataGrid-root.custom-table.drive .MuiDataGrid-main .MuiDataGrid-cell a {
  display: flex;
  align-items: center;
  line-height: 15px !important;
  font-size: 14px !important;

  overflow-wrap: break-word;
  word-break: break-word;
  white-space: break-spaces;
}

.MuiDataGrid-panelWrapper .MuiTypography-root {
  text-transform: capitalize;
}

.custom-table .toolbar-container {
  flex-wrap: wrap;
  justify-content: flex-end;
}

.MuiDataGrid-root.custom-table {
  flex-direction: column-reverse;
}

.MuiDataGrid-root {
  box-shadow: 0px 0px 6px 0px #94949433;
}
